.modal {
    
    background: rgba(33,33,33,.1) !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    width: auto !important;
    height: auto !important;

    div {
        padding: 0px !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        background: rgba(33,33,33,.1) !important;
    }

    i {
        display: none !important;
    }

}