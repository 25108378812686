/* Margin */
@mixin mT($amount) {
    margin-top: $amount !important;
}

@mixin mR($amount) {
    margin-right: $amount !important;
}

@mixin mL($amount) {
    margin-left: $amount !important;
}

@mixin mB($amount) {
    margin-bottom: $amount !important;
}

/* Padding */
@mixin pd($amount) {
    padding: $amount !important;
}

@mixin pb($amount) {
    padding-bottom: $amount !important;
}

@mixin ph($amount) {
    padding-left: $amount !important;
    padding-left: $amount !important;
}

/* Typography */
@mixin fn($size) { font-size: $size !important;}

/* Transitions */
@mixin tAll($speed) {
    transition: all $speed ease-in-out !important;
}