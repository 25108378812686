@import './util.scss';
@import './color.scss';

body {
    background: $site-bg;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

.ui.grid {
    height: 100%;
}

.ui.segment {
    flex-grow: 1 !important;
}

.flex-100 {
    height: 100% !important;
}


.cEmbedWrap {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    .cEmbed {
        // display: flex !important;
        // max-width: 480px !important;
        // height: 80% !important;
        // border: 1px solid rgba(133,133,133,.25);
        // border-radius: 6px;
    }

}

.mockLinkText {
    &:hover {
        cursor: pointer;
        color: rgba(133,133,133,.75) !important;
    }
}

.substackIcon {
    background: url('https://api.iconify.design/simple-icons:substack.svg') no-repeat center center / contain;
}