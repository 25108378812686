.itemCol {

    cursor: pointer;
    
    img {
        border: 2px solid grey !important;
        transform: scale(1) !important;
        transition: .15s all ease-in-out;
    }
    
    &:hover {
        img {
            transform: scale(1.0125) !important;
            border: 2px solid white !important;
        }
    }

}