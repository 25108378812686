@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.mintPanel, .cBundlePanel {
    overflow: hidden;

    &:not(.closed) {
        animation: appear .55s normal  forwards;
    }

    &.closed {
        animation: disappear .55s normal forwards;
    }
}

.backButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
}