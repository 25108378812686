
@import './utilMixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&family=Work+Sans&display=swap');
@import './color.scss';

/////////////////
/* Transitions */
/////////////////


/////////////
/* Padding */
/////////////

// All
.pd-0 {@include pd(0rem)}
// Horizontal
.ph-0 {@include ph(0rem)}
// Bottom
.pb-0 {@include pb(0rem)}

////////////
/* Margin */
////////////

// Top
.mt-0 {@include mT(0rem)}
.mt-h {@include mT(.5rem)}
.mt-1 {@include mT(1rem)}
.mt-1-5 {@include mT(1.5rem)}
.mt-2 {@include mT(2rem)}
.mt-2-5 {@include mT(2.5rem)}
.mt-3 {@include mT(3rem)}

// Right
.mr-0 {@include mR(0rem)}
.mr-h {@include mR(.5rem)}
.mr-1 {@include mR(1rem)}
.mr-1-5 {@include mR(1.5rem)}
.mr-2 {@include mR(2rem)}
.mr-2-5 {@include mR(2.5rem)}
.mr-3 {@include mR(3rem)}

// Left
.ml-0 {@include mL(0rem)}
.ml-h {@include mL(.5rem)}
.ml-1 {@include mL(1rem)}
.ml-1-5 {@include mL(1.5rem)}
.ml-2 {@include mL(2rem)}
.ml-2-5 {@include mL(2.5rem)}
.ml-3 {@include mL(3rem)}

// Bottom
.mb-0 {@include mB(0rem)}
.mb-h {@include mB(.5rem)}
.mb-1 {@include mB(1rem)}
.mb-1-5 {@include mB(1.5rem)}
.mb-2 {@include mB(2rem)}
.mb-2-5 {@include mB(2.5rem)}
.mb-3 {@include mB(3rem)}


/* Color */
.white {color: $white !important};

////////////////
/* Typography */
////////////////

// Fonts
.primaryFont {font-family: "Crimson Text" !important; font-weight: 600;}
.secondaryFont {font-family: "Work Sans" !important;}

// Size
.ft-12 {
    font-size: 12px !important;
}

.ft-14 {
    font-size: 14px !important;
}

// Other
.uppercase {text-transform: uppercase !important;}

//////////////////////////
/* Generic Site Styling */
//////////////////////////
.image-box-styling {
    // border: 2px solid rgba(33,33,33,1) !important;
    box-shadow: 1px 1px 3px 0px rgba(33,33,33,.25), -1px -1px 3px 0px rgba(33,33,33,.25) !important;
    border: 1px solid rgba(14,14,14,.35) !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
}

.hover-tilt {
    cursor: pointer;
    @include tAll(.25s);
    &:hover {
        transform: rotate(6deg) scale(1.1) !important;
    }
}

.hover-accent {
    cursor: pointer;
    @include tAll(.25s);
    &:hover {
        color: $secondary-color !important;
        div, i,h1,h2,h3,h4,h5 {
            color: $secondary-color !important;
        }
        path {
            fill: $secondary-color !important;
        }
    }
}

.limit-img-tablet-380 {
    @media screen and (max-width:997px){
        max-width: 380px !important;
    }
}

.fill-button {
    height: 100% !important;
    width: 100% !important;
}