/* Color Vars */
$main-color: #FF0000;
$secondary-color: #8084b1;
$tertiary-color: #0000FF;

$white:rgba(248,248,248,1);

$col1:#FF2536;
$col2:#FFA323;
$col3:#FFFF1F;
$col4:#FFFE76;
$col5:#FFFFA5;
$col6:#FFc5b3;
$col7:#FF33BB;
$col8:#FF95E0;
$col9:#FF8D8E;
$col10: #FF1D1E;
$col11: #FF1113;
$col12: #FFEB15;

/* Site Constants */
$site-bg: $white;